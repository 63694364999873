import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import KampusBadge from 'components/shared/KampusBadge';
import Rows from 'components/shared/Layout/Rows';
import { isDemoMode } from 'features/demo-mode/helpers';

export type KampusDrawerHeaderProps = {
  mini?: boolean;
};

function KampusDrawerHeader({ mini = false }: KampusDrawerHeaderProps) {
  return (
    <Rows>
      <Box
        sx={{
          height: '4rem',
          display: 'flex',
          paddingX: (theme) => theme.spacing(3),
        }}
      >
        <KampusBadge size="small" showLogotype={!mini} />
      </Box>
      {isDemoMode() && (
        <Tooltip title="You are browsing in demo mode. Data has been anonymized.">
          <Chip
            label="DEMO"
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightMedium,
              color: theme.palette.kortxAccentMagenta.main,
              backgroundColor: `${alpha(
                theme.palette.kortxAccentMagenta.main,
                0.1
              )}`,
              cursor: 'help',
            })}
          ></Chip>
        </Tooltip>
      )}
    </Rows>
  );
}

export default KampusDrawerHeader;
