import { createSelector } from '@reduxjs/toolkit';
import {
  AdvertiserDto,
  AdvertiserFileDto,
  CustomReportDto,
  CustomReportDtoDestinationEnum,
} from '@kortxio/hub-api';
import {
  AdvertiserByIdState,
  AdvertiserByIdUiState,
  FlattenedAdvertiserFile,
} from 'features/advertiser/types';
import { DocumentFileTypeFilterLabel } from 'features/campaign/types';
import { FILE_TYPE_FILTER_METADATA } from 'features/campaign/util';
import {
  CustomReportMetadata,
  ReportTypes,
} from 'features/reports/campaigns/types';
import { customReportToRoute } from 'features/reports/utils';
import { RequestState } from 'features/shared/request';
import { formatDateSimple } from 'libs/date';
import { DashboardDto } from 'libs/gen/analyticsBaseApi';
import { RootState } from 'store/types';

export const advertiserRootSelector = (state: RootState): AdvertiserByIdState =>
  state.advertiserById;

export const selectedAdvertiserDataSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserByIdState): AdvertiserDto | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { selectedAdvertiser } = advertiserRoot;

    if (!selectedAdvertiser) {
      return undefined;
    }

    const { data } = selectedAdvertiser;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const selectedAdvertiserRequestSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserByIdState): RequestState | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { selectedAdvertiser } = advertiserRoot;

    if (!selectedAdvertiser) {
      return undefined;
    }

    const { request } = selectedAdvertiser;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const selectedAdvertiserSelector = selectedAdvertiserDataSelector;

export const customDashBoardsForSelectedAdvertiserSelector = createSelector(
  advertiserRootSelector,
  (state: AdvertiserByIdState): DashboardDto[] | undefined => {
    if (state.customReports.data && Array.isArray(state.customReports.data)) {
      return state.customReports.data
        .filter(
          (customReport) =>
            customReport.destination ===
            CustomReportDtoDestinationEnum.AdvertiserDashboard
        )
        .map((customReport) => toDashBoardDto(customReport));
    }
  }
);

function toDashBoardDto(
  report: CustomReportDto
): DashboardDto & { description: string } {
  const params = [
    report.url ? 'url=' + report.url : '',
    report.name ? 'name=' + report.name : '',
  ].join('&');
  return {
    sourceTypes: ['CUSTOM_REPORTS'],
    id: 'custom-report?' + params,
    name: report.name ?? '',
    url: 'custom-report?' + report.url,
    description: report.description ?? '',
  };
}

export const customReportsForAdvertiserRequestSelector = createSelector(
  advertiserRootSelector,
  (state: AdvertiserByIdState) => state.customReports.request
);

export const customReportsForCampaignDataSelector = createSelector(
  advertiserRootSelector,
  (state: AdvertiserByIdState): CustomReportDto[] | undefined =>
    state.customReports.data
);

export const customReportsForAdvertiserOverviewSelector = createSelector(
  customReportsForCampaignDataSelector,
  (customReportData): CustomReportDto[] | undefined => {
    if (customReportData && Array.isArray(customReportData)) {
      return customReportData.filter(
        (customReport) =>
          customReport.destination ===
          CustomReportDtoDestinationEnum.AdvertiserOverview
      );
    }
  }
);

export const selectedAdvertiserOverviewCustomReportMetadataSelector =
  createSelector(
    customReportsForAdvertiserOverviewSelector,
    (
      customReports: CustomReportDto[] | undefined
    ): CustomReportMetadata[] | undefined => {
      if (!customReports) {
        return undefined;
      }

      const metadataForCustomReports: CustomReportMetadata[] =
        customReports.map((report) => ({
          id: `custom-${report.id}`,
          reportName: customReportToRoute(report),
          tabNameAsParam: customReportToRoute(report),
          label: report.name ?? '',
          url: report.url,
          reportType: ReportTypes.CUSTOM,
          destination: report.destination,
        }));

      return metadataForCustomReports;
    }
  );

export const advertiserDocumentsDataSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserByIdState): AdvertiserFileDto[] | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { documents } = advertiserRoot;

    if (!documents) {
      return undefined;
    }
    return documents.data;
  }
);

export const advertiserDocumentsRequestSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserByIdState): RequestState | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { documents } = advertiserRoot;

    if (!documents) {
      return undefined;
    }

    return documents.request;
  }
);

export const advertiserDocumentsAsRowsDataSelector = createSelector(
  advertiserDocumentsDataSelector,
  (
    documents: AdvertiserFileDto[] | undefined
  ): FlattenedAdvertiserFile[] | undefined => {
    return documents?.map((document) => ({
      ...document,
      ...document.properties,
    }));
  }
);

export const advertiserDocumentsAsRowsWithSearchSelector = (
  search: string | undefined,
  fileTypeFilterLabel: DocumentFileTypeFilterLabel
) =>
  createSelector(
    advertiserDocumentsAsRowsDataSelector,
    (documents): FlattenedAdvertiserFile[] | undefined => {
      if (!documents) {
        return undefined;
      }

      const documentsFilteredByFileType = filterDocumentsByFileTypeFilterLabel(
        documents,
        fileTypeFilterLabel
      );

      if (!search) {
        return documentsFilteredByFileType;
      }

      return documentsFilteredByFileType.filter((document) => {
        const {
          name = '',
          parsedFileName = '',
          createdAt,
          updatedAt,
        } = document ?? {};

        const possibleMatchFields = [
          name,
          parsedFileName,
          formatDateSimple(createdAt) || '',
          formatDateSimple(updatedAt) || '',
        ];

        const searchLowerCase = search.toLowerCase();

        return possibleMatchFields.some((field) =>
          field.toLowerCase().includes(searchLowerCase)
        );
      });
    }
  );

export const advertiserByIdUiSelector = createSelector(
  advertiserRootSelector,
  (advertiserByIdState: AdvertiserByIdState): AdvertiserByIdUiState =>
    advertiserByIdState.ui
);

export const searchForAdvertiserByIdDocumentsSelector = createSelector(
  advertiserByIdUiSelector,
  (ui: AdvertiserByIdUiState): string | undefined => ui.searchForDocuments
);

export const fileTypeFilterForAdvertiserByIdDocumentsSelector = createSelector(
  advertiserByIdUiSelector,
  (ui: AdvertiserByIdUiState): DocumentFileTypeFilterLabel =>
    ui.fileTypeFilterLabel
);

export const filterDocumentsByFileTypeFilterLabel = (
  documents: AdvertiserFileDto[],
  label: DocumentFileTypeFilterLabel
): AdvertiserFileDto[] => {
  const metadata = FILE_TYPE_FILTER_METADATA[label];

  let newDocuments = [...documents];

  if (metadata.includedMimeTypes) {
    newDocuments = newDocuments.filter(
      (document) =>
        document.mimeType &&
        metadata.includedMimeTypes?.includes(document.mimeType)
    );
  }

  if (metadata.excludedMimeTypes) {
    newDocuments = newDocuments.filter(
      (document) =>
        document.mimeType &&
        !metadata.excludedMimeTypes?.includes(document.mimeType)
    );
  }

  return newDocuments;
};
