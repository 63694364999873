import { GridOverlay } from '@mui/x-data-grid-premium';
import DocumentSVG from 'components/shared/DocumentSVG';

export default function NoRowsGridOverlay({
  message,
  isEmpty,
}: {
  message: string;
  isEmpty: boolean;
}) {
  if (!isEmpty) {
    return <></>;
  }

  return (
    <GridOverlay>
      <DocumentSVG message={message} />
    </GridOverlay>
  );
}
