import { createSelector } from '@reduxjs/toolkit';
import {
  CampaignAsTableRowProjectionDto,
  CampaignDto,
  CampaignMetricsDto,
  RequestForProposalFormOutputDto,
} from '@kortxio/hub-api';
import { RequestState } from 'features/shared/request';
import { RootState } from 'store/types';
import {
  CampaignAsOption,
  CampaignAsRow,
  CampaignsState,
  CampaignsUiState,
  dtoToCampaignAsRow,
  RequestForProposalFormAsRow,
  tableRowProjectionDtoToCampaignAsRow,
} from './types';

export const campaignRootSelector = (state: RootState): CampaignsState =>
  state.campaigns;

export const campaignMetricsDataSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): CampaignMetricsDto | undefined =>
    campaignRoot.metrics.data
);

export const campaignMetricsRequestSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): RequestState | undefined => {
    if (!campaignRoot) {
      return undefined;
    }

    const { metrics } = campaignRoot;

    if (!metrics) {
      return undefined;
    }

    const { request } = metrics;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const campaignMetricsSelector = campaignMetricsDataSelector;

export const campaignsRequestSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): RequestState =>
    campaignRoot?.campaigns?.request
);

export const campaignsDataSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState) => campaignRoot?.campaigns?.data
);

export const campaignsAsRowsSelector = createSelector(
  campaignsDataSelector,
  (campaigns: CampaignDto[] | undefined): CampaignAsRow[] => {
    return (
      campaigns?.map(
        (campaign): CampaignAsRow =>
          dtoToCampaignAsRow(campaign) as CampaignAsRow
      ) ?? []
    );
  }
);

export const campaignsAsRowsSelectorWithSearchSelector = (search: string) =>
  createSelector(
    campaignsAsRowsSelector,
    (campaignsAsRows): CampaignAsRow[] => {
      return campaignsAsRows.filter((campaign) => {
        const campaignName = campaign.name || '';
        const advertiserName = campaign.advertiserName || '';

        return (
          campaignName.toLowerCase().includes(search.toLowerCase()) ||
          advertiserName.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
  );

export const campaignsByStatusInRequestSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): RequestState =>
    campaignRoot?.campaignsByStatusIn?.request
);

export const campaignsByStatusInDataSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState) => campaignRoot?.campaignsByStatusIn?.data
);

export const campaignsByStatusInAsRowsSelector = createSelector(
  campaignsByStatusInDataSelector,
  (campaigns: CampaignDto[] | undefined): CampaignAsRow[] => {
    return (
      campaigns?.map(
        (campaign): CampaignAsRow => dtoToCampaignAsRow(campaign)
      ) ?? []
    );
  }
);

export const campaignsByStatusInSelectorWithSearchSelector = (search: string) =>
  createSelector(
    campaignsByStatusInAsRowsSelector,
    (campaignsAsRows): CampaignAsRow[] => {
      return campaignsAsRows.filter((campaign) => {
        const campaignName = campaign.name || '';
        const advertiserName = campaign.advertiserName || '';

        return (
          campaignName.toLowerCase().includes(search.toLowerCase()) ||
          advertiserName.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
  );

export const campaignsByAdvertiserIdDataSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState) => campaignRoot?.campaignsByAdvertiserId?.data
);

export const campaignsByAdvertiserIdSelector =
  campaignsByAdvertiserIdDataSelector;

export const campaignByIdSelector = createSelector(
  [
    campaignsByAdvertiserIdDataSelector,
    (_, campaignId: string | undefined) => campaignId,
  ],
  (campaigns, campaignId): CampaignDto | undefined =>
    (campaigns ?? []).find((campaign) => {
      return (campaign?.id?.toString() ?? '') === campaignId;
    })
);

export const campaignsByAdvertiserIdAsRowsSelector = createSelector(
  campaignsByAdvertiserIdSelector,
  (campaigns: CampaignDto[] | undefined): CampaignAsRow[] => {
    return (
      campaigns?.map(
        (campaign): CampaignAsRow =>
          dtoToCampaignAsRow(campaign) as CampaignAsRow
      ) ?? []
    );
  }
);

export const campaignsByAdvertiserIdAsRowsWithSearchSelector = (
  search: string
) =>
  createSelector(
    campaignsByAdvertiserIdAsRowsSelector,
    (campaignsAsRows): CampaignAsRow[] => {
      return campaignsAsRows.filter((campaign) => {
        const campaignName = campaign.name || '';
        const advertiserName = campaign.advertiserName || '';

        return (
          campaignName.toLowerCase().includes(search.toLowerCase()) ||
          advertiserName.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
  );

export const campaignsByAdvertiserIdAndPlatformInDataSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState) =>
    campaignRoot?.campaignsByAdvertiserIdAndPlatformIn?.data
);

export const campaignsByAdvertiserIdAndPlatformInSelector =
  campaignsByAdvertiserIdAndPlatformInDataSelector;

export const campaignsByAdvertiserIdAndPlatformInAsOptionsSelector =
  createSelector(
    campaignsByAdvertiserIdAndPlatformInSelector,
    (campaigns: CampaignDto[] | undefined): CampaignAsOption[] => {
      if (campaigns === undefined) {
        return [];
      }

      return campaigns
        .map(
          (value) =>
            ({
              id: value.id,
              label: value.campaignName,
              campaignStartDate: value.campaignStartDate,
              campaignEndDate: value.campaignEndDate,
            } as CampaignAsOption)
        )
        .sort((a, b) => {
          if (a.campaignStartDate === undefined) {
            return -1;
          }
          if (b.campaignStartDate === undefined) {
            return 1;
          }

          return b.campaignStartDate
            .trim()
            .localeCompare(a.campaignStartDate.trim());
        });
    }
  );

export const campaignsUsingTableProjectionRequestSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): RequestState =>
    campaignRoot?.campaignsUsingTableRowProjection?.request
);

export const campaignsUsingTableRowProjectionDataSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState) =>
    campaignRoot?.campaignsUsingTableRowProjection?.data
);

export const campaignsUsingTableRowProjectionAsRowsSelector = createSelector(
  campaignsUsingTableRowProjectionDataSelector,
  (
    campaigns: CampaignAsTableRowProjectionDto[] | undefined
  ): CampaignAsRow[] => {
    return (
      campaigns?.map(
        (campaign): CampaignAsRow =>
          tableRowProjectionDtoToCampaignAsRow(campaign)
      ) ?? []
    );
  }
);

export const campaignsUsingTableRowProjectionAsRowsWithSearchSelector = (
  search: string
) =>
  createSelector(
    campaignsUsingTableRowProjectionAsRowsSelector,
    (campaignsAsRows): CampaignAsRow[] => {
      return campaignsAsRows.filter((campaign) => {
        const campaignName = campaign.name || '';
        const advertiserName = campaign.advertiserName || '';

        return (
          campaignName.toLowerCase().includes(search.toLowerCase()) ||
          advertiserName.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
  );

export const campaignsByStatusInUsingTableProjectionRequestSelector =
  createSelector(
    campaignRootSelector,
    (campaignRoot: CampaignsState): RequestState =>
      campaignRoot?.campaignsByStatusInUsingTableRowProjection?.request
  );

export const campaignsByStatusInUsingTableRowProjectionDataSelector =
  createSelector(
    campaignRootSelector,
    (campaignRoot: CampaignsState) =>
      campaignRoot?.campaignsByStatusInUsingTableRowProjection?.data
  );

export const campaignsByStatusInUsingTableRowProjectionAsRowsSelector =
  createSelector(
    campaignsByStatusInUsingTableRowProjectionDataSelector,
    (
      campaigns: CampaignAsTableRowProjectionDto[] | undefined
    ): CampaignAsRow[] => {
      return (
        campaigns?.map(
          (campaign): CampaignAsRow =>
            tableRowProjectionDtoToCampaignAsRow(campaign)
        ) ?? []
      );
    }
  );

export const campaignsByStatusInUsingTableRowProjectionAsRowsWithSearchSelector =
  (search: string) =>
    createSelector(
      campaignsByStatusInUsingTableRowProjectionAsRowsSelector,
      (campaignsAsRows): CampaignAsRow[] => {
        return campaignsAsRows.filter((campaign) => {
          const campaignName = campaign.name || '';
          const advertiserName = campaign.advertiserName || '';

          return (
            campaignName.toLowerCase().includes(search.toLowerCase()) ||
            advertiserName.toLowerCase().includes(search.toLowerCase())
          );
        });
      }
    );

export const campaignsByAdvertiserIdUsingTableProjectionRequestSelector =
  createSelector(
    campaignRootSelector,
    (campaignRoot: CampaignsState): RequestState =>
      campaignRoot?.campaignsByAdvertiserIdUsingTableRowProjection?.request
  );

export const campaignsByAdvertiserIdUsingTableRowProjectionDataSelector =
  createSelector(
    campaignRootSelector,
    (campaignRoot: CampaignsState) =>
      campaignRoot?.campaignsByAdvertiserIdUsingTableRowProjection?.data
  );

export const campaignsByAdvertiserIdUsingTableRowProjectionAsRowsSelector =
  createSelector(
    campaignsByAdvertiserIdUsingTableRowProjectionDataSelector,
    (
      campaigns: CampaignAsTableRowProjectionDto[] | undefined
    ): CampaignAsRow[] => {
      return (
        campaigns?.map(
          (campaign): CampaignAsRow =>
            tableRowProjectionDtoToCampaignAsRow(campaign)
        ) ?? []
      );
    }
  );

export const campaignsByAdvertiserIdUsingTableRowProjectionAsRowsWithSearchSelector =
  (search: string) =>
    createSelector(
      campaignsByAdvertiserIdUsingTableRowProjectionAsRowsSelector,
      (campaignsAsRows): CampaignAsRow[] => {
        return campaignsAsRows.filter((campaign) => {
          const campaignName = campaign.name || '';
          const advertiserName = campaign.advertiserName || '';

          return (
            campaignName.toLowerCase().includes(search.toLowerCase()) ||
            advertiserName.toLowerCase().includes(search.toLowerCase())
          );
        });
      }
    );

export const requestForProposalFormsRequestSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): RequestState =>
    campaignRoot.requestForProposalForms.request
);

export const requestForProposalFormsDataSelector = createSelector(
  campaignRootSelector,
  (
    campaignRoot: CampaignsState
  ): RequestForProposalFormOutputDto[] | undefined =>
    campaignRoot.requestForProposalForms.data
);

export const requestForProposalFormsCountSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): number | undefined => {
    return campaignRoot.requestForProposalForms.count;
  }
);

export const requestForProposalFormsAsRowsSelector = createSelector(
  requestForProposalFormsDataSelector,
  (
    data: RequestForProposalFormOutputDto[] | undefined
  ): RequestForProposalFormAsRow[] => {
    return (data ?? []) as RequestForProposalFormAsRow[];
  }
);

export const requestForProposalFormsAsRowsWithSearchSelector = (
  search: string
) =>
  createSelector(
    requestForProposalFormsAsRowsSelector,
    (requestForProposalFormsAsRows): RequestForProposalFormAsRow[] => {
      return requestForProposalFormsAsRows.filter((requestForProposalForm) => {
        const campaignName = requestForProposalForm.campaignName || '';
        const advertiserName = requestForProposalForm.advertiser || '';

        return (
          campaignName.toLowerCase().includes(search.toLowerCase()) ||
          advertiserName.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
  );

export const campaignUiSelector = createSelector(
  campaignRootSelector,
  (campaignRoot: CampaignsState): CampaignsUiState | undefined => {
    return campaignRoot?.ui;
  }
);

export const searchForCampaignsOrRequestForProposalFormsSelector =
  createSelector(
    campaignUiSelector,
    (ui: CampaignsUiState | undefined): string => {
      return ui?.searchForCampaignsOrRequestForProposalForms || '';
    }
  );

export const searchForCampaignsByAdvertiserIdSelector = createSelector(
  campaignUiSelector,
  (ui: CampaignsUiState | undefined): string => {
    return ui?.searchForCampaignsByAdvertiserId || '';
  }
);
