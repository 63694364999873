import React, { ReactElement, useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export type SearchInputProps = {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  className?: string;
  placeholder?: string;
  width?: number | string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  inputBaseProps?: InputBaseProps;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: alpha(theme.palette.common.black, 0.23),
    borderRadius: '0.375rem',
    backgroundColor: alpha(theme.palette.common.black, 0.025),
    '&:hover': {
      borderColor: alpha(theme.palette.common.black, 0.87),
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  leftIconBox: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
  },
  rightIconBox: {
    padding: theme.spacing(0, 0.5),
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.text.secondary,
  },
  inputBaseRoot: {
    width: '100%',
    color: theme.palette.text.primary,
  },
  inputBaseInput: {
    width: '100%',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
  },
}));

function SearchInput({
  value = '',
  onChange,
  onClear,
  className,
  width = 300,
  placeholder = 'Search...',
  leftIcon = <SearchIcon />,
  rightIcon,
  inputBaseProps = {},
}: SearchInputProps) {
  const { classes, cx } = useStyles();

  const showClearIconButton = useMemo(
    () => Boolean(value) && Boolean(onClear),
    [onClear, value]
  );

  return (
    <Box className={cx(classes.root, className)} sx={{ width }}>
      <Box className={classes.leftIconBox}>{leftIcon}</Box>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputBaseRoot,
          input: classes.inputBaseInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={onChange}
        {...inputBaseProps}
      />
      {showClearIconButton && (
        <Box className={classes.rightIconBox}>
          <IconButton size="small" onClick={onClear} aria-label="clear-search">
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      {rightIcon && <Box className={classes.rightIconBox}>{rightIcon}</Box>}
    </Box>
  );
}

export default SearchInput;
