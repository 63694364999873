import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import BreadcrumbIcon from 'components/shared/breadcrumbs/BreadcrumbIcon';

const useStyles = makeStyles()((theme) => ({
  breadCrumbs: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightSemiBold,
    textDecoration: 'none',
  },
}));

export type BreadcrumbElement = {
  text: string;
  link?: string;
};

export type KampusBreadcrumbsProps = {
  elements: BreadcrumbElement[];
};

export default function KampusBreadcrumbs({
  elements,
  ...props
}: KampusBreadcrumbsProps) {
  const { classes } = useStyles();

  return (
    <Box display="flex" alignItems="center" minHeight={18} {...props}>
      <Breadcrumbs
        separator={
          <BreadcrumbIcon
            sx={(theme) => ({
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            })}
          />
        }
        className={classes.breadCrumbs}
      >
        {elements.map((element) => {
          return (
            <Typography
              key={element.text}
              className={classes.breadCrumbs}
              variant="subtitle2"
              {...(element.link ? { component: Link, to: element.link } : {})}
            >
              {element.text}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}
