import { GridComparatorFn } from '@mui/x-data-grid-premium';
import {
  BudgetGroupDto,
  CampaignDto,
  CampaignDtoStatusEnum,
  MediaLineItemDto,
} from '@kortxio/hub-api';
import { addDays, isAfter, parseISO } from 'date-fns';
import { toPercentage } from 'libs/formatters';
import { toTitleCase, usdCurrencyNumberFormat } from 'libs/string';

export const defaultStatusSortOrder: CampaignDtoStatusEnum[] = [
  CampaignDtoStatusEnum.Blocked,
  CampaignDtoStatusEnum.Paused,
  CampaignDtoStatusEnum.Live,
  CampaignDtoStatusEnum.Pending,
  CampaignDtoStatusEnum.Draft,
  CampaignDtoStatusEnum.Deal,
  CampaignDtoStatusEnum.Completed,
  CampaignDtoStatusEnum.Cancelled,
];

export const statusGridSortComparator: GridComparatorFn<
  CampaignDtoStatusEnum | undefined
> = (value1, value2) => {
  if (value1 === undefined) {
    return 1;
  }

  if (value2 === undefined) {
    return -1;
  }

  return (
    defaultStatusSortOrder.indexOf(value1) -
    defaultStatusSortOrder.indexOf(value2)
  );
};

export function statusToStatusAsChipLabel(
  status: CampaignDtoStatusEnum | undefined
) {
  if (status === undefined) {
    return undefined;
  }

  if (status === CampaignDtoStatusEnum.Draft) {
    return 'Pre-Launch';
  }

  return toTitleCase(status);
}

export function getDaysRemainingForCampaign(campaign: CampaignDto) {
  const { daysRemaining } = campaign;

  if (!daysRemaining) {
    return 0;
  }

  return daysRemaining >= 0 ? daysRemaining : 0;
}

// Given a campaign and today's date, determine if the campaign is over.
// Adds one day for extra safety :D
export const isCampaignOver = (
  today: Date,
  campaign?: CampaignDto
): boolean => {
  if (campaign?.campaignEndDate === undefined) return false;
  const threshold = addDays(parseISO(campaign.campaignEndDate), 1);
  // Our threshold is one day after the campaign end.
  return isAfter(today, threshold);
};

export function getImpressionsForBudgetGroup(budgetGroup: BudgetGroupDto) {
  const rateType = budgetGroup?.rateType;

  const isAddedValue = rateType === 'ADDED_VALUE';

  if (isAddedValue) {
    const { addedValueImpressions } = budgetGroup || {};

    return addedValueImpressions || 0;
  }

  const isCPM = rateType === 'CPM';

  if (!isCPM) {
    return 0;
  }

  const { budget, rate } = budgetGroup;

  const isValidBudget = budget && !Number.isNaN(budget);
  const isValidRate = rate && !Number.isNaN(rate);

  if (!isValidBudget || !isValidRate) {
    return 0;
  }

  return Math.round((budget / rate) * 1000);
}

export function getImpressionsForLineItem(lineItem: MediaLineItemDto) {
  const rateType = lineItem?.rateType;

  const isAddedValue = rateType === 'ADDED_VALUE';

  if (isAddedValue) {
    const { addedValueImpressions } = lineItem || {};

    return addedValueImpressions || 0;
  }

  const isCPM = rateType === 'CPM';

  if (!isCPM) {
    return 0;
  }

  const { budgetAmount: budget, rate } = lineItem;

  const isValidBudget = budget && !Number.isNaN(budget);
  const isValidRate = rate && !Number.isNaN(rate);

  if (!isValidBudget || !isValidRate) {
    return 0;
  }

  return Math.round((budget / rate) * 1000);
}

export function getStartDateForLineItem(lineItem: MediaLineItemDto) {
  const rateType = lineItem?.rateType;

  const isHourlyRateOrFixedFee =
    rateType === 'HOURLY_RATE' || rateType === 'FIXED_FEE';

  if (isHourlyRateOrFixedFee) {
    return undefined;
  }

  return lineItem.startFlightDate;
}

export function getEndDateForLineItem(lineItem: MediaLineItemDto) {
  const rateType = lineItem?.rateType;

  const isHourlyRateOrFixedFee =
    rateType === 'HOURLY_RATE' || rateType === 'FIXED_FEE';

  if (isHourlyRateOrFixedFee) {
    return undefined;
  }

  return lineItem.endFlightDate;
}

export function getFormattedRateType(rateType: string | undefined) {
  if (rateType === undefined) {
    return '';
  }

  if (rateType === 'CPM') {
    return 'CPM';
  }

  return toTitleCase(rateType);
}

export function getFormattedRate(
  rate: number | undefined,
  rateType: string | undefined
) {
  if (rate === undefined) {
    return '-';
  }

  if (rateType === 'FLAT_RATE' || rateType === 'COST_PLUS') {
    return toPercentage(rate);
  }

  if (rateType === 'CPM') {
    return usdCurrencyNumberFormat.format(Number(rate));
  }

  return '-';
}
