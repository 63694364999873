import { ReactNode, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { darkTheme } from 'theme';
import { UserWithMetadataDtoRoleEnum } from '@kortxio/hub-api';
import { useAuth } from 'features/auth/UseAuth';
import AutoCompleteClientSelect from 'features/client/components/AutoCompleteClientSelect';
import { selectedClientSelector } from 'features/client/selectors';
import { isDemoMode, setDemoMode } from 'features/demo-mode/helpers';
import RequireRole from 'features/user/components/RequireRole';
import { useCtrlKeyDown } from 'hooks/useOnKeyDown';
import { useAppSelector } from 'store/hooks';

export type ProfileMenuProps = {
  text?: ReactNode;
  tooltipText?: ReactNode;
  textFieldPlaceholder?: string;
};

export default function ProfileMenu({
  text,
  tooltipText,
  textFieldPlaceholder,
}: ProfileMenuProps) {
  const { logout } = useAuth();

  const selectedClient = useAppSelector(selectedClientSelector);

  const { id: selectedClientId } = selectedClient ?? {};

  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>();

  const isMenuOpen = Boolean(anchorElement);

  const menuDomElement = useRef<HTMLButtonElement | null>(null);

  const isDemoModeActive = isDemoMode();

  const onClickIconButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorElement(undefined);
  };

  const onClickMenuItem = () => {
    onCloseMenu();
  };

  useCtrlKeyDown(() => {
    if (menuDomElement.current) {
      setAnchorElement(menuDomElement.current);
    }
  }, 'p');

  const userManagementLinkDisabled = Boolean(!selectedClientId);

  return (
    <>
      <Tooltip
        title={tooltipText !== undefined ? tooltipText : null}
        placement="right"
      >
        <Button
          ref={menuDomElement}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={onClickIconButton}
          size="large"
          sx={{
            justifyContent: 'start',
            color: 'inherit',
            flex: 1,
          }}
        >
          <AccountCircleOutlinedIcon sx={{ m: 0.5 }} />
          {text}
        </Button>
      </Tooltip>
      <ThemeProvider theme={darkTheme}>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElement}
          open={isMenuOpen}
          onClose={onCloseMenu}
          sx={{ ml: 11 }}
        >
          <RequireRole
            requiredRoles={[
              UserWithMetadataDtoRoleEnum.KortxAdmin,
              UserWithMetadataDtoRoleEnum.KortxStandard,
              UserWithMetadataDtoRoleEnum.PartnerSolutions,
            ]}
          >
            <MenuItem
              onKeyDown={(e) => {
                // This way we can prevent the menu from capturing the keydown event
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            >
              <AutoCompleteClientSelect
                onClose={onCloseMenu}
                textFieldPlaceholder={textFieldPlaceholder}
              />
            </MenuItem>
          </RequireRole>
          <MenuItem
            onClick={onClickMenuItem}
            component={RouterLink}
            to="/profile"
          >
            Your profile
          </MenuItem>
          <RequireRole
            requiredRoles={[
              UserWithMetadataDtoRoleEnum.Admin,
              UserWithMetadataDtoRoleEnum.KortxAdmin,
              UserWithMetadataDtoRoleEnum.KortxStandard,
              UserWithMetadataDtoRoleEnum.PartnerSolutions,
            ]}
          >
            <MenuItem
              onClick={onClickMenuItem}
              component={RouterLink}
              to={`/clients/${selectedClientId}/user-management`}
              disabled={userManagementLinkDisabled}
            >
              User management
            </MenuItem>
          </RequireRole>
          <RequireRole requiredRoles={[UserWithMetadataDtoRoleEnum.KortxAdmin]}>
            <MenuItem
              onClick={onClickMenuItem}
              component={RouterLink}
              to={`/admin/user-management`}
              disabled={userManagementLinkDisabled}
            >
              Administration
            </MenuItem>
          </RequireRole>
          <RequireRole
            requiredRoles={[
              UserWithMetadataDtoRoleEnum.KortxStandard,
              UserWithMetadataDtoRoleEnum.KortxAdmin,
              UserWithMetadataDtoRoleEnum.PartnerSolutions,
            ]}
          >
            <MenuItem
              onClick={() => {
                if (!isDemoModeActive) {
                  window.open('/demo-mode-controller', '_blank');
                }
                setDemoMode(!isDemoModeActive);
              }}
            >
              {isDemoModeActive ? 'Turn off Demo Mode' : 'Turn on Demo Mode'}
              {isDemoModeActive ? (
                <CancelPresentationIcon
                  style={{
                    marginLeft: '4px',
                  }}
                />
              ) : (
                <PresentToAllIcon
                  style={{
                    marginLeft: '4px',
                  }}
                />
              )}
            </MenuItem>
          </RequireRole>
          <Box px={1.5}>
            <hr />
          </Box>
          <MenuItem onClick={logout}>Sign out</MenuItem>
        </Menu>
      </ThemeProvider>
    </>
  );
}
