import React, { PropsWithChildren } from 'react';
import { SxProps, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Variant } from '@mui/material/styles/createTypography';
import Typography, {
  TypographyPropsVariantOverrides,
} from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';
import KampusBreadcrumbs, {
  BreadcrumbElement,
} from 'components/shared/breadcrumbs/KampusBreadcrumbs';
import { WithOptionalClearbitLogo } from 'components/shared/ClearbitLogo';

export type ReportingSuiteHeaderProps = {
  title: string;
  titleTypographyVariant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  sx?: SxProps<Theme>;
  clearbitLogoUrl?: string;
  subheader?: React.ReactNode;
  breadcrumbs?: BreadcrumbElement[];
  toolbar?: React.ReactNode;
} & PropsWithChildren;

// children appear below the title, toolbar to the right of the title
// toolbar is optional
// no children, not bottom toolbar! pass anything if you want a blank toolbar.
export default function PageHeader({
  title,
  titleTypographyVariant = 'h6',
  sx,
  clearbitLogoUrl,
  subheader,
  breadcrumbs,
  toolbar,
  children,
}: ReportingSuiteHeaderProps) {
  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  const showPageHeaderTitleWithClearbitLogo =
    matchesUpMd && clearbitLogoUrl !== undefined && clearbitLogoUrl !== '';

  return (
    <Stack>
      <Stack
        sx={{
          px: 3,
          pt: 2.3,
          pb: children == undefined ? 2 : 0,
        }}
        spacing={0.5}
      >
        {breadcrumbs && (
          <Box>
            <KampusBreadcrumbs elements={breadcrumbs} />
          </Box>
        )}
        <Stack
          direction={{
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
          }}
          useFlexGap
          flexWrap="wrap"
          spacing={1}
          sx={{
            justifyContent: 'space-between',
            ...sx,
          }}
        >
          <Stack direction="row" spacing={2}>
            {showPageHeaderTitleWithClearbitLogo && (
              <WithOptionalClearbitLogo url={clearbitLogoUrl} width={52}>
                <Stack spacing={0.5}>
                  <Typography variant={titleTypographyVariant}>
                    {title}
                  </Typography>
                  {subheader && <>{subheader}</>}
                </Stack>
              </WithOptionalClearbitLogo>
            )}
            {!showPageHeaderTitleWithClearbitLogo && (
              <Stack spacing={0.5}>
                <Typography variant={titleTypographyVariant}>
                  {title}
                </Typography>
                {subheader && <>{subheader}</>}
              </Stack>
            )}
          </Stack>
          {toolbar && <>{toolbar}</>}
        </Stack>
      </Stack>
      {children && (
        <Box
          sx={{
            display: 'flex',
            px: 3,
            py: 2,
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      )}
    </Stack>
  );
}
