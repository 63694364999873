import { useTheme } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export type BreadCrumbIconImageProps = SvgIconProps & {
  size?: number;
  fillColor?: string;
};

export default function BreadcrumbIcon({
  size = 20,
  fillColor = undefined,
  ...props
}: BreadCrumbIconImageProps) {
  const theme = useTheme();

  const fill = fillColor ? fillColor : theme.palette.grey[500];

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: size,
        width: size,
      }}
      {...props}
    >
      <svg fill={fill} aria-hidden="true">
        <path d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"></path>
      </svg>
    </SvgIcon>
  );
}
