import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import {
  GridRow,
  GridRowProps,
  RowPropsOverrides,
} from '@mui/x-data-grid-premium';

export default function GridRowWithOptionalRouterLink({
  getRowAsRouterLinkTo,
  row,
  ...props
}: GridRowProps & RowPropsOverrides) {
  const rowAsRouterLinkTo =
    getRowAsRouterLinkTo !== undefined
      ? `${getRowAsRouterLinkTo(row)}`
      : undefined;

  return (
    <>
      {rowAsRouterLinkTo !== undefined && (
        <Link
          to={rowAsRouterLinkTo}
          component={RouterLink}
          sx={{ textDecoration: 'none', color: 'inherit' }}
        >
          <GridRow row={row} {...props} />
        </Link>
      )}
      {rowAsRouterLinkTo === undefined && <GridRow row={row} {...props} />}
    </>
  );
}
