import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { UserWithMetadataDtoRoleEnum } from '@kortxio/hub-api';
import SearchInput from 'components/shared/inputs/SearchInput';
import Columns from 'components/shared/Layout/Columns';
import Filler from 'components/shared/Layout/Filler';
import Rows from 'components/shared/Layout/Rows';
import PageHeader from 'components/shared/PageHeader';
import { getAllCampaignsUsingTableRowProjection } from 'features/campaigns/async';
import { ClientAsOption } from 'features/client/types';
import { CampaignControllerDataGrid } from 'features/demo-mode/components/CampaignControllerDataGrid';
import { ClientControllerDataGrid } from 'features/demo-mode/components/ClientControllerDataGrid';
import RequireRole from 'features/user/components/RequireRole';
import WithDocumentTitle from 'routes/components/WithDocumentTitle';
import { useAppDispatch } from 'store/hooks';
import { demoModeBroadcast } from '../features/demo-mode/helpers';

export default function DemoModeController() {
  const dispatch = useAppDispatch();

  const [clientOption, setClientOption] = useState<ClientAsOption>();
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (clientOption !== undefined) {
      dispatch(
        getAllCampaignsUsingTableRowProjection({ clientId: clientOption.id })
      );
    }
  }, [clientOption, dispatch]);

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSearch(event.target.value ?? ''),
    [setSearch]
  );
  const onSearchClear = useCallback(() => setSearch(''), [setSearch]);

  const onGoToClients = useCallback(() => {
    onSearchClear();
    setClientOption(undefined);
  }, [setClientOption, onSearchClear]);

  return (
    <RequireRole
      redirectTo="/"
      requiredRoles={[
        UserWithMetadataDtoRoleEnum.KortxStandard,
        UserWithMetadataDtoRoleEnum.KortxAdmin,
        UserWithMetadataDtoRoleEnum.PartnerSolutions,
      ]}
    >
      <WithDocumentTitle title="Demo Mode">
        <Rows
          sx={{
            height: '100vh',
            width: '100vw',
            p: 1,
          }}
        >
          <PageHeader title="Demo Mode" />
          <Columns>
            <SearchInput
              value={search}
              onChange={onSearchChange}
              onClear={onSearchClear}
            />
            {clientOption !== undefined && (
              <Columns
                sx={{
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                <Filler />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    demoModeBroadcast.postMessage(
                      `/clients/${clientOption.id}/home`
                    );
                  }}
                >
                  View {clientOption.label}
                  <OpenInNewIcon sx={{ ml: 1 }} />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onGoToClients}
                  sx={{
                    ml: 2,
                  }}
                >
                  Go Back to Clients
                </Button>
              </Columns>
            )}
          </Columns>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              mt: 1,
            }}
          >
            {clientOption === undefined && (
              <ClientControllerDataGrid
                search={search}
                onSearchClear={onSearchClear}
                onSelect={setClientOption}
              />
            )}
            {clientOption !== undefined && (
              <>
                <CampaignControllerDataGrid search={search} />
              </>
            )}
          </Box>
        </Rows>
      </WithDocumentTitle>
    </RequireRole>
  );
}
