import { AxiosResponse } from 'axios';
import {
  demoAddresses,
  demoCatchPhrases,
  demoCompanyNames, demoModeEmails, demoPersonFirstNames, demoPersonLastNames,
  demoPersonNames,
  demoPhoneNumbers,
  demoPrices,
  demoUrls,
  randomItemIn,
} from 'features/demo-mode/helpers';
import {
  IdentifiableFieldMetadata,
  IdentifiableFieldTypes,
} from 'features/demo-mode/types';

const IDENTIFIABLE_FIELD_NAME = '__identifiableFields' as const;

export function toDemoModeResponse(response: AxiosResponse) {
  const { data, ...props } = response;

  const newData = toDemoModeData(data);

  return {
    ...props,
    data: newData,
  };
}

export function toDemoModeData(
  data: Record<string, unknown> | Record<string, unknown>[] | null
): unknown {
  if (data == null || typeof data !== 'object') {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((element) => toDemoModeData(element));
  }

  // From here on out, data is a non-nullish object
  const hasIdentifiableFields = Object.hasOwn(data, IDENTIFIABLE_FIELD_NAME);
  if (hasIdentifiableFields) {
    const identifiableFieldMetadatas = data[
      IDENTIFIABLE_FIELD_NAME
    ] as IdentifiableFieldMetadata[];

    identifiableFieldMetadatas.forEach((metadata) => {
      data[metadata.fieldName] = toDemoField(metadata);
    });
  }

  // Recursively process rest of object tree to look for identifiable fields
  for (const dataKey in data) {
    const value = data[dataKey];

    if (typeof value === 'object') {
      data[dataKey] = toDemoModeData(value as Record<string, unknown>);
    }
  }

  return data;
}

function toDemoField(fieldMetadata: IdentifiableFieldMetadata) {
  switch (fieldMetadata.type) {
    case IdentifiableFieldTypes.Address:
      return randomItemIn(demoAddresses);
    case IdentifiableFieldTypes.ClientName:
    case IdentifiableFieldTypes.AdvertiserName:
    case IdentifiableFieldTypes.CompanyName:
      return randomItemIn(demoCompanyNames);
    case IdentifiableFieldTypes.CampaignName:
      return randomItemIn(demoCatchPhrases);
    case IdentifiableFieldTypes.Dollar:
      return randomItemIn(demoPrices);
    case IdentifiableFieldTypes.PersonName:
      return randomItemIn(demoPersonNames);
    case IdentifiableFieldTypes.PersonFirstName:
      return randomItemIn(demoPersonFirstNames);
    case IdentifiableFieldTypes.PersonLastName:
      return randomItemIn(demoPersonLastNames);
    case IdentifiableFieldTypes.Email:
      return randomItemIn(demoModeEmails);
    case IdentifiableFieldTypes.PhoneNumber:
      return randomItemIn(demoPhoneNumbers);
    case IdentifiableFieldTypes.Website:
      return randomItemIn(demoUrls);
    case IdentifiableFieldTypes.Generic:
    default:
      return 'REDACTED';
  }
}
