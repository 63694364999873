import { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box/Box';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import {
  CategoryNameEnum,
  NotificationPreferencesInputDto,
} from '@kortxio/hub-api';
import { useNotificationPreferences } from '@magicbell/react-headless';
import { CategoryChannelPreference } from '@magicbell/react-headless/dist/types/IRemoteNotificationPreferences';
import Filler from 'components/shared/Layout/Filler';
import { userVisibleMagicBellCategories } from 'components/shared/magicbell/helper';
import MagicBellInboxPreferencesListItem from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInbox/components/MagicBellInboxPreferencesListItem';

export type MagicBellInboxPreferencesListProps = BoxProps & {
  handleCloseMenu: () => void;
  handleUpdatePreferences: (
    requestBody: NotificationPreferencesInputDto
  ) => unknown;
};

function filterAndSortMagicBellCategories(
  categories: CategoryChannelPreference[]
) {
  return categories
    .filter((category) =>
      userVisibleMagicBellCategories.includes(category.slug)
    )
    .map((category) => {
      let description;

      switch (category.slug) {
        case CategoryNameEnum.General:
          description = 'Announcements + news from the KORTX team';
          break;
        case CategoryNameEnum.Billing:
          description = 'Get notified when there are new or past due invoices';
          break;
        case CategoryNameEnum.Campaign:
          description = 'Get notified about campaign updates';
          break;
        case CategoryNameEnum.Dashboard:
          description = 'Get notified about new dashboards';
          break;
        case CategoryNameEnum.Document:
          description = 'Get notified about new documents';
          break;
        case CategoryNameEnum.Support:
          description = 'Get notified about support ticket updates';
          break;
        default:
          description = '';
      }

      return {
        ...category,
        description: description,
      };
    })
    .sort((a, b) => {
      // MagicBell ordering can be inconsistent
      const nameA = a.slug;
      const nameB = b.slug;

      // Force "General" on top, always
      if (nameA === CategoryNameEnum.General) {
        return -1;
      }
      if (nameB === CategoryNameEnum.General) {
        return 1;
      }

      if (nameA < nameB) {
        return -1;
      }

      return nameA > nameB ? 1 : 0;
    });
}

export default function MagicBellInboxPreferencesList({
  handleCloseMenu,
  handleUpdatePreferences,
  ...props
}: MagicBellInboxPreferencesListProps) {
  const { categories, fetch: getAllNotificationPreferences } =
    useNotificationPreferences();

  const categoriesToShow = useMemo(
    () => filterAndSortMagicBellCategories(categories),
    [categories]
  );

  useEffect(() => {
    getAllNotificationPreferences();
  }, [getAllNotificationPreferences]);

  const hasInAppChannel = categoriesToShow
    .flatMap((category) => category.channels)
    .some((channel) => channel.slug === 'in_app');
  const hasEmailChannel = categoriesToShow
    .flatMap((category) => category.channels)
    .some((channel) => channel.slug === 'email');

  return (
    <Box {...props}>
      <Stack direction="row" sx={{ px: 2, pt: 1 }}>
        <Filler />
        {hasInAppChannel && (
          <Typography variant="body2" sx={{ width: 60, textAlign: 'center' }}>
            In App
          </Typography>
        )}
        {hasEmailChannel && (
          <Typography variant="body2" sx={{ width: 60, textAlign: 'center' }}>
            Email
          </Typography>
        )}
      </Stack>
      <MenuList dense>
        {categoriesToShow.map((category) => (
          <MagicBellInboxPreferencesListItem
            key={category.slug}
            category={category}
            handleCloseMenu={handleCloseMenu}
            handleUpdatePreferences={handleUpdatePreferences}
          />
        ))}
      </MenuList>
    </Box>
  );
}
