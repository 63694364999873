import { useMemo } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid-premium';
import { CampaignAsTableRowProjectionDto } from '@kortxio/hub-api';
import KampusDataGrid from 'components/shared/KampusDataGrid';
import {
  campaignsUsingTableProjectionRequestSelector,
  campaignsUsingTableRowProjectionDataSelector,
} from 'features/campaigns/selectors';
import { demoModeBroadcast } from 'features/demo-mode/helpers';
import { useAppSelector } from 'store/hooks';

const campaignColumns: GridColDef<CampaignAsTableRowProjectionDto>[] = [
  {
    field: 'advertiserName',
    headerName: 'Advertiser',
    flex: 1,
    renderCell: ({ row }) => (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          demoModeBroadcast.postMessage(
            `/clients/${row.clientId}/advertisers/${row.advertiserId}`
          );
        }}
        sx={{
          textWrap: 'wrap',
        }}
      >
        {row.advertiserName}
        <OpenInNewIcon sx={{ ml: 1 }} />
      </Button>
    ),
  },
  {
    field: 'name',
    headerName: 'Campaign',
    flex: 1,
    renderCell: ({ row }) => (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          demoModeBroadcast.postMessage(
            `/clients/${row.clientId}/campaigns/${row.id}`
          );
        }}
        sx={{
          textWrap: 'wrap',
        }}
      >
        {row.name}
        <OpenInNewIcon sx={{ ml: 1 }} />
      </Button>
    ),
  },
  {
    field: 'actions',
    flex: 0.5,
    headerName: '',
    renderCell: ({ row }) => (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          demoModeBroadcast.postMessage(
            `/clients/${row.clientId}/reporting-suite/${row.advertiserId}/campaign-reporting/${row.id}`
          );
        }}
        sx={{
          ml: 1,
        }}
      >
        Reporting
        <OpenInNewIcon sx={{ ml: 1 }} />
      </Button>
    ),
  },
];

type CampaignControllerDataGridProps = {
  search: string;
};

export function CampaignControllerDataGrid({
  search,
}: CampaignControllerDataGridProps) {
  const { isLoading = true } =
    useAppSelector(campaignsUsingTableProjectionRequestSelector) ?? {};
  const campaigns = useAppSelector(
    campaignsUsingTableRowProjectionDataSelector
  );

  const filteredCampaigns = useMemo(() => {
    const searchLower = search.toLowerCase();

    return campaigns?.filter(
      (campaign) =>
        campaign.name !== undefined &&
        campaign.name.toLowerCase().includes(searchLower)
    );
  }, [campaigns, search]);

  return (
    <KampusDataGrid
      rows={filteredCampaigns}
      columns={campaignColumns}
      noRowsMessage="No Campaigns"
      loading={isLoading}
    />
  );
}
