const DEMO_MODE_KEY = 'demo-mode' as const;

export function isDemoMode(): boolean {
  return sessionStorage.getItem(DEMO_MODE_KEY) === 'true';
}

export function setDemoMode(isDemoMode: boolean) {
  if (isDemoMode) {
    sessionStorage.setItem(DEMO_MODE_KEY, 'true');
  } else {
    sessionStorage.removeItem(DEMO_MODE_KEY);
  }

  window.location.reload();
}

export const demoModeBroadcast = new BroadcastChannel(DEMO_MODE_KEY);

demoModeBroadcast.onmessage = (e: MessageEvent<string>) => {
  window.location.assign(e.data);
};

export function randomItemIn(list: string[]): string {
  return list[Math.floor(Math.random() * list.length)];
}

export const demoAddresses = [
  '1062 Gutkowski Hollow',
  '5547 Wanda Ridge',
  '991 The Paddocks',
  '4794 Runolfsdottir Fields',
  '751 Grove Road',
  '18945 Long Lane',
  '14215 Homenick Key',
  '4411 Leuschke Mountains',
  '4116 Xavier Dam',
  '44181 Anderson Hill',
  '25488 Marquardt Lock',
  '832 Ima Ramp',
  '6644 Liberty Street',
  '6478 Wilton Brooks',
  '21173 Walker Station',
  '779 S Main',
  '771 Ayden Field',
  '1751 Dawson Lane',
  '8160 Luettgen Mission',
  '655 Raheem Forge',
  '183 Beer Flat',
  '845 Sycamore Drive',
  '742 Grange Avenue',
  '2222 Henriette Wall',
  '208 Rau Centers',
  '517 Fisher Valleys',
  '9459 Schmidt Pike',
  '697 Stamm Isle',
  '720 Hamill Union',
  '496 The Causeway',
  '49893 Kris Brook',
  '4883 Lexie Parkway',
  '101 Alysson Terrace',
  '7201 Clifton Road',
  '727 Godfrey Road',
  '11164 Americo Islands',
  '40982 Beulah Landing',
  '6990 St George Road',
  '64917 Forest Road',
  '687 Cortez Mill',
  '768 Mount Street',
  '331 Mraz Centers',
  '3156 Satterfield Park',
  '13829 E 12th Street',
  '12182 Bosco Causeway',
  '304 Hoeger Fall',
  '424 Nicola Pike',
  '30986 Bradtke Drives',
  '419 Front Street',
  '4369 The Paddock',
];

export const demoCompanyNames = [
  'Okuneva LLC',
  'Sanford - Stark',
  'Lubowitz - Sanford',
  'Walsh, Rempel and Bernhard',
  'Konopelski and Sons',
  'Franey, Dibbert and Vandervort',
  'Zemlak Group',
  'Mueller, Schiller and Oberbrunner',
  'Effertz, Murazik and Lakin',
  'Mills - Schumm',
  'Howell LLC',
  'Larson - Bailey',
  'Collins, Turcotte and Kshlerin',
  'Kirlin - Nader',
  'Fahey Inc',
  'Torp, Zulauf and Lockman',
  'Bashirian, Hessel and Morissette',
  'Sipes and Sons',
  'Rutherford - Jacobson',
  'Stark - Metz',
  'Predovic Group',
  'Paucek and Sons',
  'Haley - Hermann',
  'Kuhic - Botsford',
  'Hahn and Sons',
  'Wisoky and Sons',
  'Thompson, Crooks and Shields',
  'Parisian, Kemmer and Hoeger',
  'Senger Inc',
  'Kuphal LLC',
  'Corkery, Ryan and Krajcik',
  'McCullough, Koepp and Moore',
  "O'Conner Group",
  'Turcotte - Marks',
  'Turner - Leuschke',
  'Mayer and Sons',
  'Leuschke - Marvin',
  'Hagenes - Cummings',
  'Gulgowski, Kautzer and Collins',
  'Schultz LLC',
  'Kassulke, Denesik and Barrows',
  'Gleason Inc',
  'Stroman - Kessler',
  'Boyle Inc',
  'Okuneva, West and Mills',
  'Runte, Schulist and Dooley',
  'Padberg, Lehner and Hayes',
  'Kertzmann - Reilly',
  'Mosciski, Shields and Dietrich',
  'Kshlerin - Donnelly',
];

export const demoCatchPhrases = [
  'Profound zero administration complexity',
  'Focused next generation algorithm',
  'Fundamental homogeneous success',
  'Phased eco-centric complexity',
  'Grass-roots impactful artificial intelligence',
  'Fully-configurable high-level knowledge user',
  'Polarised 24 hour firmware',
  'Extended homogeneous neural-net',
  'Centralized uniform local area network',
  'Self-enabling national local area network',
  'Grass-roots analyzing standardization',
  'Robust attitude-oriented leverage',
  'Polarised client-driven framework',
  'Smart AI-powered approach',
  'Balanced transitional workforce',
  'Face to face dynamic workforce',
  'Streamlined cloud-native help-desk',
  'Compatible data-driven algorithm',
  'Ergonomic zero administration functionalities',
  'Business-focused AI-powered artificial intelligence',
  'Universal empowering attitude',
  'Reactive hybrid benchmark',
  'Focused encompassing definition',
  'Face to face hybrid hub',
  'Realigned logistical challenge',
  'Expanded responsive budgetary management',
  'Immersive homogeneous encryption',
  'Phased attitude-oriented workforce',
  'Universal maximized conglomeration',
  'Self-enabling intangible architecture',
  'Integrated content-based synergy',
  'Seamless 24/7 ability',
  'Adaptive asynchronous data-warehouse',
  'Enhanced data-driven adapter',
  'Triple-buffered impactful initiative',
  'Progressive analyzing hierarchy',
  'Seamless sustainable adapter',
  'Focused incremental analyzer',
  'Fundamental cohesive capacity',
  'Balanced bifurcated ability',
  'Integrated stable forecast',
  'Virtual tangible artificial intelligence',
  'Devolved methodical data-warehouse',
  'Stand-alone content-based task-force',
  'Fully-configurable cloud-native database',
  'Networked zero defect encoding',
  'Synchronised 24 hour concept',
  'Centralized maximized moratorium',
  'Persevering tertiary middleware',
  'Persistent encompassing framework',
];

export const demoPrices = [
  '853.79',
  '9.91',
  '591.79',
  '878.05',
  '572.95',
  '970.90',
  '60.89',
  '729.85',
  '356.75',
  '431.38',
  '397.09',
  '953.67',
  '174.09',
  '197.81',
  '117.99',
  '106.55',
  '995.95',
  '459.55',
  '780.65',
  '413.49',
  '855.59',
  '26.15',
  '471.15',
  '300.19',
  '72.79',
  '336.99',
  '218.45',
  '170.76',
  '216.29',
  '737.39',
  '447.59',
  '379.69',
  '959.99',
  '833.50',
  '634.95',
  '32.65',
  '332.59',
  '881.50',
  '920.99',
  '313.29',
  '131.67',
  '525.09',
  '505.49',
  '537.30',
  '235.29',
  '739.36',
  '880.79',
  '568.99',
  '142.79',
  '64.69',
];

export const demoPersonNames = [
  'Barbara Marvin',
  'Maria Feil',
  'Joe Gutkowski',
  'Florence Cormier',
  'Evan Herman V',
  'Steven Hodkiewicz',
  'Bernice Hyatt',
  'Sheila Hegmann',
  'Roberto Rau',
  'Lamar Vandervort',
  'Sharon Kshlerin',
  'Rosa Padberg',
  'April Erdman',
  'Edmond Jacobson V',
  'Sheryl Pfeffer',
  'Leigh Gutkowski',
  'Wayne Kuvalis',
  'Floyd Mohr-Goyette',
  'Diane Romaguera',
  'Ervin Ward',
  'Winston Altenwerth',
  'Shelly Mayert',
  'Eloise Greenfelder',
  'Noah Windler',
  'Ernest Cormier',
  'Raymond Kuphal',
  'Jaime Bernier',
  'Darrin Russel',
  'Harry Conn',
  'Ida Koss',
  'Mark Johns',
  'Josefina Reilly',
  'Dr. Geneva Schmeler III',
  'Hugh Runolfsson',
  'Edmund Heller',
  'Kristopher Schmitt',
  'Johanna Harris',
  'Dr. Beulah Leuschke',
  'Kelvin Bechtelar-Kutch',
  'Dr. Genevieve Carter DDS',
  'Mary Conn',
  'Preston Hudson',
  'Samuel Schmeler',
  'Oscar Mraz-Renner',
  'Gayle Carroll',
  'Rhonda Fritsch',
  'Sylvia Nicolas',
  'Marshall Walker',
  'Guadalupe Jenkins',
  'Dr. Alvin Bernhard',
];

export const demoPersonFirstNames = [
  'Barbara',
  'Maria',
  'Joe',
  'Florence',
  'Evan',
  'Steven',
  'Bernice',
  'Sheila',
  'Roberto',
  'Lamar',
  'Sharon',
  'Rosa',
  'April',
  'Edmond',
  'Sheryl',
  'Leigh',
  'Wayne',
  'Floyd',
  'Diane',
  'Ervin',
  'Winston',
  'Shelly',
  'Eloise',
  'Noah',
  'Ernest',
  'Raymond',
  'Jaime',
  'Darrin',
  'Harry',
  'Ida',
  'Mark',
  'Josefina',
  'Hugh',
  'Edmund',
  'Kristopher',
  'Johanna',
  'Kelvin',
  'Mary',
  'Preston',
  'Samuel',
  'Oscar',
  'Gayle',
  'Rhonda',
  'Sylvia',
  'Marshall',
  'Guadalupe',
];

export const demoPersonLastNames = [
  'Marvin',
  'Feil',
  'Gutkowski',
  'Cormier',
  'Herman V',
  'Hodkiewicz',
  'Hyatt',
  'Hegmann',
  'Rau',
  'Vandervort',
  'Kshlerin',
  'Padberg',
  'Erdman',
  'Jacobson V',
  'Pfeffer',
  'Gutkowski',
  'Kuvalis',
  'Goyette',
  'Romaguera',
  'Ward',
  'Altenwerth',
  'Mayert',
  'Greenfelder',
  'Windler',
  'Cormier',
  'Kuphal',
  'Bernier',
  'Russel',
  'Conn',
  'Koss',
  'Johns',
  'Reilly',
  'Schmeler III',
  'Runolfsson',
  'Heller',
  'Schmitt',
  'Harris',
  'Leuschke',
  'Kutch',
  'Conn',
  'Hudson',
  'Schmeler',
  'Renner',
  'Carroll',
  'Fritsch',
  'Nicolas',
  'Walker',
  'Jenkins',
  'Bernhard',
];

export const demoPhoneNumbers = [
  '1-997-538-5909 x2255',
  '381.745.8276 x306',
  '350-908-8026 x44358',
  '920-686-5702',
  '1-313-738-0607 x12756',
  '979.903.3433',
  '719-758-1776 x491',
  '886-862-3164 x883',
  '(215) 990-0797 x59991',
  '(247) 454-0507 x552',
  '453.980.3329 x2731',
  '1-892-387-6516 x264',
  '(946) 627-6900 x3719',
  '1-776-961-1908 x63204',
  '896.224.8604 x751',
  '438-599-2505 x28648',
  '342-999-7935 x426',
  '(542) 206-1708',
  '529-259-4348 x557',
  '1-937-789-3144',
  '649.695.8678 x5366',
  '1-467-365-2768',
  '590.676.3344 x198',
  '1-735-374-4434 x38990',
  '261.712.4848 x05058',
  '730-873-5502 x6168',
  '(775) 274-8839 x05754',
  '(215) 468-9798 x0108',
  '843.662.1781 x85507',
  '721.247.2644 x712',
  '(483) 382-4255',
  '(736) 262-4676 x15620',
  '1-342-803-9907',
  '1-976-213-6376',
  '922-421-2112 x3366',
  '316-470-8124 x2371',
  '1-225-769-1694',
  '1-612-418-6357',
  '515-407-6747',
  '(268) 371-0996 x98569',
  '470.370.2180',
  '993-472-2612 x77608',
  '(767) 843-5535',
  '(978) 289-3922 x83196',
  '686.383.3138',
  '825.663.2630',
  '(544) 629-5648',
  '933.651.5767',
  '741-526-6835 x147',
  '(631) 986-9498 x00961',
];

export const demoUrls = [
  'https://soulful-flat.org',
  'https://common-breastplate.biz',
  'https://enraged-clavicle.name/',
  'https://warmhearted-alliance.biz',
  'https://vivacious-hyena.org',
  'https://measly-quit.name',
  'https://quixotic-incandescence.name',
  'https://low-embarrassment.com/',
  'https://thrifty-gradient.com',
  'https://crowded-hallway.org/',
  'https://portly-final.name/',
  'https://ugly-handful.info',
  'https://whirlwind-developing.net/',
  'https://innocent-mozzarella.com/',
  'https://unfinished-puritan.name',
  'https://physical-vision.com',
  'https://questionable-night.info',
  'https://ambitious-accountability.info',
  'https://difficult-cellar.com/',
  'https://soupy-boulevard.com',
  'https://specific-elver.biz/',
  'https://gripping-gym.org',
  'https://physical-reach.net/',
  'https://steel-scarification.name/',
  'https://fuzzy-detective.com/',
  'https://soft-compromise.name/',
  'https://worst-trade.com/',
  'https://trim-deer.org/',
  'https://splendid-experience.biz',
  'https://unique-dish.info/',
  'https://obedient-amendment.com/',
  'https://secondary-toothpick.name',
  'https://bony-iridescence.org',
  'https://glossy-wedding.com',
  'https://favorite-secret.net',
  'https://selfish-bend.com/',
  'https://gorgeous-season.org/',
  'https://downright-charm.biz',
  'https://magnificent-yeast.name',
  'https://tattered-perfection.org/',
  'https://strident-injunction.com',
  'https://immediate-ad.biz',
  'https://hard-to-find-interviewer.info/',
  'https://gorgeous-gloom.net',
  'https://parallel-concentration.biz/',
  'https://dual-allocation.net',
  'https://secret-premium.net/',
  'https://dazzling-mainstream.info',
  'https://buttery-fat.org',
  'https://glaring-catalyst.com/',
];

export const demoModeEmails = [
  'example@soulful-flat.org',
  'example@common-breastplate.biz',
  'example@enraged-clavicle.name',
  'example@warmhearted-alliance.biz',
  'example@vivacious-hyena.org',
  'example@measly-quit.name',
  'example@quixotic-incandescence.name',
  'example@low-embarrassment.com',
  'example@thrifty-gradient.com',
  'example@crowded-hallway.org',
  'example@portly-final.name',
  'example@ugly-handful.info',
  'example@whirlwind-developing.net',
  'example@innocent-mozzarella.com',
  'example@unfinished-puritan.name',
  'example@physical-vision.com',
  'example@questionable-night.info',
  'example@ambitious-accountability.info',
  'example@difficult-cellar.com',
  'example@soupy-boulevard.com',
  'example@specific-elver.biz',
  'example@gripping-gym.org',
  'example@physical-reach.net',
  'example@steel-scarification.name',
  'example@fuzzy-detective.com',
  'example@soft-compromise.name',
  'example@worst-trade.com',
  'example@trim-deer.org',
  'example@splendid-experience.biz',
  'example@unique-dish.info',
  'example@obedient-amendment.com',
  'example@secondary-toothpick.name',
  'example@bony-iridescence.org',
  'example@glossy-wedding.com',
  'example@favorite-secret.net',
  'example@selfish-bend.com',
  'example@gorgeous-season.org',
  'example@downright-charm.biz',
  'example@magnificent-yeast.name',
  'example@tattered-perfection.org',
  'example@strident-injunction.com',
  'example@immediate-ad.biz',
  'example@hard-to-find-interviewer.info',
  'example@gorgeous-gloom.net',
  'example@parallel-concentration.biz',
  'example@dual-allocation.net',
  'example@secret-premium.net',
  'example@dazzling-mainstream.info',
  'example@buttery-fat.org',
  'example@glaring-catalyst.com',
];
