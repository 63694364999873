import { GridOverlay } from '@mui/x-data-grid-premium';
import DocumentSVG from 'components/shared/DocumentSVG';

export default function NoResultsGridOverlay({ message }: { message: string }) {
  return (
    <GridOverlay>
      <DocumentSVG message={message} />
    </GridOverlay>
  );
}
