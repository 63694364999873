import { ObjectValues } from 'libs/types/types';

export const IdentifiableFieldTypes = {
  Generic: 'Generic',
  Address: 'Address',
  ClientName: 'ClientName',
  AdvertiserName: 'AdvertiserName',
  CampaignName: 'CampaignName',
  CompanyName: 'CompanyName',
  Dollar: 'Dollar',
  PersonName: 'PersonName',
  PersonFirstName: 'PersonFirstName',
  PersonLastName: 'PersonLastName',
  Email: 'Email',
  PhoneNumber: 'PhoneNumber',
  Website: 'Website',
} as const;
export type IdentifiableFieldType = ObjectValues<typeof IdentifiableFieldTypes>;

export type BaseIdentifiableFieldMetadata = {
  fieldName: string;
  params?: string;
};

export type GenericIdentifiableMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.Generic;
};

export type IdentifiableAddressMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.Address;
};

export type IdentifiableClientNameMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.ClientName;
};

export type IdentifiableAdvertiserNameMetadata =
  BaseIdentifiableFieldMetadata & {
    type: typeof IdentifiableFieldTypes.AdvertiserName;
  };

export type IdentifiableCampaignNameMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.CampaignName;
};

export type IdentifiableCompanyNameMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.CompanyName;
};

export type IdentifiableDollarMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.Dollar;
};

export type IdentifiablePersonNameMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.PersonName;
};

export type IdentifiablePersonFirstNameMetadata =
  BaseIdentifiableFieldMetadata & {
    type: typeof IdentifiableFieldTypes.PersonFirstName;
  };

export type IdentifiablePersonLastNameMetadata =
  BaseIdentifiableFieldMetadata & {
    type: typeof IdentifiableFieldTypes.PersonLastName;
  };

export type IdentifiableEmailMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.Email;
};

export type IdentifiablePhoneNumberMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.PhoneNumber;
};

export type IdentifiableWebsiteMetadata = BaseIdentifiableFieldMetadata & {
  type: typeof IdentifiableFieldTypes.Website;
};

export type IdentifiableFieldMetadata =
  | GenericIdentifiableMetadata
  | IdentifiableAddressMetadata
  | IdentifiableClientNameMetadata
  | IdentifiableAdvertiserNameMetadata
  | IdentifiableCampaignNameMetadata
  | IdentifiableCompanyNameMetadata
  | IdentifiableDollarMetadata
  | IdentifiablePersonNameMetadata
  | IdentifiablePersonFirstNameMetadata
  | IdentifiablePersonLastNameMetadata
  | IdentifiableEmailMetadata
  | IdentifiablePhoneNumberMetadata
  | IdentifiableWebsiteMetadata;
