import React from 'react';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridLoadingOverlay,
  GridRowModel,
  GridRowsProp,
  GridToolbarProps,
} from '@mui/x-data-grid-premium';
import { makeStyles } from 'tss-react/mui';
import GridColumnMenu from 'components/shared/KampusDataGrid/GridColumnMenu';
import GridFilterPanelWithoutColumnsWhereVisibilityIsFalse from 'components/shared/KampusDataGrid/GridFilterPanelWithoutColumnsWhereVisibilityIsFalse';
import GridRowWithOptionalRouterLink from 'components/shared/KampusDataGrid/GridRowWithOptionalRouterLink';
import KampusDataGridToolbar from 'components/shared/KampusDataGrid/KampusDataGridToolbar';
import NoResultsGridOverlay from 'components/shared/KampusDataGrid/NoResultsGridOverlay';
import NoRowsGridOverlay from 'components/shared/KampusDataGrid/NoRowsGridOverlay';

declare module '@mui/x-data-grid-premium' {
  interface NoRowsOverlayPropsOverrides {
    message: string;
    isEmpty: boolean;
  }

  interface NoResultsOverlayPropsOverrides {
    message: string;
  }

  interface RowPropsOverrides {
    rowAsRouterLinkTo?: string;
  }
}

export type KampusDataGridProps = {
  noRowsMessage?: string;
  noResultsMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  NoRowsOverlay?: React.JSXElementConstructor<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  NoResultsOverlay?: React.JSXElementConstructor<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LoadingOverlay?: React.JSXElementConstructor<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnMenu?: React.JSXElementConstructor<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterPanel?: React.JSXElementConstructor<any>;
  rows?: GridRowsProp;
  getRowAsRouterLinkTo?: (row: GridRowModel) => string;
  toolbarProps?: GridToolbarProps;
  exportId?: string;
} & Omit<DataGridPremiumProps, 'rows'>;

const useStyles = makeStyles()((theme) => ({
  root: {
    '--unstable_DataGrid-radius': 0,
    border: 'none',
    '& .MuiDataGrid-main': {
      flex: '1 1 0px',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      outline: 'none !important',
    },
    // To debug id generation, one can comment the following rule:
    // It will reveal the buttons in the native table toolbar.
    '& .MuiDataGrid-toolbarContainer': {
      display: 'none',
    },
    '& .MuiDataGrid-container--top .MuiDataGrid-columnHeaders [role=row]': {
      backgroundColor: theme.palette.grey[50],
    },
    // CUSTOM:
    // Add these class to a GridColDef<> to add 3rem padding to the left/right
    '& .first-column': {
      paddingLeft: theme.spacing(3),
    },
    '& .last-column': {
      paddingRight: theme.spacing(3),
    },
  },
}));

function KampusDataGridWithoutPagination({
  className,
  rows,
  getRowAsRouterLinkTo,
  noRowsMessage = 'No Rows',
  noResultsMessage = 'No Results Found',
  NoRowsOverlay = NoRowsGridOverlay,
  NoResultsOverlay = NoResultsGridOverlay,
  LoadingOverlay = GridLoadingOverlay,
  ColumnMenu = GridColumnMenu,
  FilterPanel = GridFilterPanelWithoutColumnsWhereVisibilityIsFalse,
  toolbarProps,
  exportId,
  ...props
}: KampusDataGridProps) {
  const { classes, cx } = useStyles();
  return (
    <DataGridPremium
      className={cx(classes.root, className)}
      rows={rows ?? []}
      density="comfortable"
      columnHeaderHeight={41}
      disableColumnPinning
      disableColumnReorder
      disableColumnSelector
      disableRowSelectionOnClick
      slots={{
        loadingOverlay: LoadingOverlay,
        noRowsOverlay: NoRowsOverlay,
        noResultsOverlay: NoResultsOverlay,
        row: GridRowWithOptionalRouterLink,
        columnMenu: ColumnMenu,
        toolbar: KampusDataGridToolbar,
        filterPanel: FilterPanel,
      }}
      slotProps={{
        toolbar: { id: exportId, ...toolbarProps },
        filterPanel: {
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mr: 2 },
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto', mr: 2, width: 150 },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto', mr: 2 },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
                sx: { width: 200 },
              },
            },
          },
          sx: {
            p: 1,
            '& .MuiDataGrid-filterForm': { p: 2 },
          },
        },
        noRowsOverlay: {
          message: noRowsMessage,
          isEmpty: Boolean(rows && rows.length === 0),
        },
        noResultsOverlay: {
          message: noResultsMessage,
        },
        row: {
          getRowAsRouterLinkTo: getRowAsRouterLinkTo,
        },
      }}
      {...props}
    />
  );
}

export default KampusDataGridWithoutPagination;
