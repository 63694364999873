import Button from '@mui/material/Button';
import {
  GridToolbarContainer,
  GridToolbarProps,
  useGridApiContext,
} from '@mui/x-data-grid-premium';

// The custom toolbar shows the export button only if it has rows.
// This can be leveraged from the outside: when the exportId set can't be found in the DOM
// we can assume the export wouldn't work. The respective menu item can be hidden.
// Here id is what the DataGrid sees as exportId we need to append the type of export there
// since the same exportId is used as prefix to exportable data grid elements.
export default function KampusDataGridToolbar({
  id,
  csvOptions,
}: { id?: string } & GridToolbarProps) {
  const apiRef = useGridApiContext();

  const hasRows = apiRef.current.getRowsCount() > 0;

  return (
    <GridToolbarContainer>
      {hasRows && (
        <Button
          id={`${id}-csv`}
          onClick={() =>
            apiRef.current.exportDataAsCsv({
              fileName: id,
              utf8WithBom: true,
              ...csvOptions,
            })
          }
        >
          Export CSV
        </Button>
      )}
    </GridToolbarContainer>
  );
}
