import React, { useState } from 'react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import theme from 'theme';

type ClearbitLogoProps = {
  url: string | undefined;
  width?: number;
  sx?: SxProps<typeof theme>;
};

// helper HOC to delay rendering of children until ClearbitLogo !isLoading
export function WithOptionalClearbitLogo({
  url,
  width = 42,
  children,
}: ClearbitLogoProps & React.PropsWithChildren) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const onLoad = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  // https://medium.com/jspoint/loose-vs-strict-equality-in-javascript-3e5136720b00
  const show = url != undefined && !hasError;

  return (
    <>
      {show && (
        <Box alignItems="center" sx={{ display: 'flex', width }}>
          <ClearbitLogoImg
            url={url}
            width={width}
            onLoad={onLoad}
            onError={onError}
          />
        </Box>
      )}
      {!isLoading && <>{children}</>}
    </>
  );
}

function ClearbitLogoImg({
  url,
  width,
  ...props
}: { url: string; width: number } & React.HTMLProps<HTMLImageElement>) {
  return (
    <img
      alt=""
      src={`https://logo.clearbit.com/${cleanupUrl(url)}`}
      style={{ width, padding: '4px' }}
      {...props}
    />
  );
}

export default function ClearbitLogo({
  url,
  width = 48,
  sx,
}: ClearbitLogoProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const onLoad = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setHasError(true);

    setIsLoading(false);
  };

  // https://medium.com/jspoint/loose-vs-strict-equality-in-javascript-3e5136720b00
  const show = url != undefined && !hasError;

  const sxWithLoading = isLoading
    ? { display: 'none' }
    : { display: 'flex', width, ...sx };

  return (
    <>
      {show && (
        <Box alignItems="center" sx={sxWithLoading}>
          <ClearbitLogoImg
            url={url}
            width={width}
            onLoad={onLoad}
            onError={onError}
          />
        </Box>
      )}
    </>
  );
}

function cleanupUrl(url: string) {
  function getHost() {
    return url.replace('https://', '').split('/')[0];
  }

  function getDomainAndTld(host: string) {
    return host.split('.').slice(-2).join('.');
  }

  const domain = getHost();
  return 'https://' + getDomainAndTld(domain);
}
