import { createSelector } from '@reduxjs/toolkit';
import { AdvertiserDto } from '@kortxio/hub-api';
import { RequestState } from 'features/shared/request';
import { RootState } from 'store/types';
import {
  AdvertiserAsOption,
  AdvertiserAsRow,
  AdvertiserState,
  AdvertiserUiState,
  dtoToAdvertiserAsRow,
} from './types';

export const advertiserRootSelector = (state: RootState): AdvertiserState =>
  state.advertiser;

export const advertisersDataSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserState): AdvertiserDto[] | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { advertisers } = advertiserRoot;

    if (!advertisers) {
      return undefined;
    }

    const { data } = advertisers;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const advertisersRequestSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserState): RequestState | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { advertisers } = advertiserRoot;

    if (!advertisers) {
      return undefined;
    }

    const { request } = advertisers;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const advertisersSelector = advertisersDataSelector;

export const advertisersAsRowsSelector = createSelector(
  advertisersSelector,
  (advertisers): AdvertiserAsRow[] => {
    if (!advertisers) {
      return [];
    }

    return [...advertisers]
      .map((advertiser) => dtoToAdvertiserAsRow(advertiser) as AdvertiserAsRow)
      .sort((a, b) => {
        if (!a.name) {
          return 1;
        }

        if (!b.name) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });
  }
);

export const advertisersAsRowsWithSearchSelector = (search: string) =>
  createSelector(
    advertisersAsRowsSelector,
    (advertisersAsRows): AdvertiserAsRow[] => {
      return advertisersAsRows.filter((advertiser) => {
        const name = advertiser.name ?? '';

        return name.toLowerCase().includes(search.toLowerCase());
      });
    }
  );

export const advertisersUsingSummaryProjectionDataSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserState): AdvertiserDto[] | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { advertisersUsingSummaryProjection } = advertiserRoot;

    if (!advertisersUsingSummaryProjection) {
      return undefined;
    }

    const { data } = advertisersUsingSummaryProjection;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const advertisersUsingSummaryProjectionRequestSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserState): RequestState | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { advertisersUsingSummaryProjection } = advertiserRoot;

    if (!advertisersUsingSummaryProjection) {
      return undefined;
    }

    const { request } = advertisersUsingSummaryProjection;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const advertisersUsingSummaryProjectionSelector =
  advertisersUsingSummaryProjectionDataSelector;

export const advertisersAsOptionsSelector = createSelector(
  advertisersUsingSummaryProjectionSelector,
  (advertisers): AdvertiserAsOption[] => {
    if (!advertisers) {
      return [];
    }

    return advertisers
      .map(
        (value) => ({ id: value.id, label: value.name } as AdvertiserAsOption)
      )
      .sort((a, b) => a.label.trim().localeCompare(b.label.trim()));
  }
);

export const advertiserByIdSelector = createSelector(
  [
    advertisersUsingSummaryProjectionSelector,
    (_, advertiserId: string | undefined) => advertiserId,
  ],
  (advertisers, advertiserId): AdvertiserDto | undefined => {
    return (advertisers ?? []).find(
      (advertiser) => (advertiser?.id?.toString() ?? '') === advertiserId
    );
  }
);

export const advertiserUiSelector = createSelector(
  advertiserRootSelector,
  (advertiserRoot: AdvertiserState): AdvertiserUiState | undefined => {
    if (!advertiserRoot) {
      return undefined;
    }

    const { ui } = advertiserRoot;

    if (!ui) {
      return undefined;
    }

    return ui;
  }
);
