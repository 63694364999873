import { useState } from 'react';
import KampusDataGridWithoutPagination, {
  KampusDataGridProps,
} from './KampusDataGridWithoutPagination';

export default function KampusDataGrid(props: KampusDataGridProps) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  return (
    <KampusDataGridWithoutPagination
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      {...props}
    />
  );
}
