import React from 'react';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';

export type WithDocumentTitleProps = {
  title: string;
} & React.PropsWithChildren;

function WithDocumentTitle({ title, children }: WithDocumentTitleProps) {
  useIsomorphicLayoutEffect(() => {
    window.document.title = title
      ? `Kampus Advanced Marketing Platform from KORTX - ${title}`
      : 'Kampus Advanced Marketing Platform from KORTX';
  }, [title]);

  return <>{children}</>;
}

export default WithDocumentTitle;
