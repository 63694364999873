import React from 'react';
import {
  GridFilterPanel,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';

type GridFilterPanelProps = React.ComponentProps<typeof GridFilterPanel>;

export default function GridFilterPanelWithoutColumnsWhereVisibilityIsFalse(
  props: GridFilterPanelProps
) {
  // mui-x does not export GridFilterPanelProps
  // https://github.com/mui/mui-x/issues/9131
  // eslint-disable-next-line react/prop-types
  const { filterFormProps } = props;

  const apiRef = useGridApiContext();

  return (
    <GridFilterPanel
      {...props}
      filterFormProps={{
        filterColumns: ({ columns }) => {
          const columnVisibilityModel =
            gridColumnVisibilityModelSelector(apiRef);

          return columns
            .filter((col) => {
              return columnVisibilityModel[col.field] !== false;
            })
            .map((col) => col.field);
        },
        ...filterFormProps,
      }}
    />
  );
}
