import { format } from 'date-fns';
import numeral from 'numeral';

export function toNumberWithDecimal(input: unknown): string {
  switch (input) {
    case 0:
      return numeral(input).format();
    case undefined:
      return numeral(0).format();
    default:
      return numeral(input).format('0,0.[00]');
  }
}

export function toThousandSeparatedInteger(input: unknown): string {
  switch (input) {
    case 0:
      return numeral(input).format();
    case undefined:
      return numeral(0).format();
    default:
      return numeral(input).format('0,0');
  }
}

export function toNumberWithAbbreviation(input: unknown): string {
  switch (input) {
    case 0:
      return numeral(input).format();
    case undefined:
      return '0';
    default:
      return numeral(input).format('0.0a').toUpperCase();
  }
}

export function toCurrency(input: unknown) {
  switch (input) {
    case undefined:
      return '0';
    default:
      return numeral(input).format('$0,0');
  }
}

export function toCurrencyWithDecimal(input: unknown) {
  switch (input) {
    case undefined:
      return '0';
    default:
      return numeral(input).format('$0,0.00');
  }
}

export function toPercentage(input: unknown) {
  switch (input) {
    case undefined:
      return '0.00%';
    default:
      return numeral(input).format('0.00%');
  }
}

export function toPercentageDash(input: unknown) {
  switch (input) {
    case undefined:
      return '-';
    default:
      return numeral(input).format('0.00%');
  }
}

export function toPercentage4(input: unknown) {
  switch (input) {
    case undefined:
      return '0.0000%';
    default:
      return numeral(input).format('0.0000%');
  }
}

export function toSeconds(input: unknown) {
  switch (input) {
    case undefined:
      return '0s';
    default:
      return `${numeral(input).format('0.0[0]')}s`;
  }
}

export function toISODate(input: unknown) {
  if (input instanceof Date) {
    return format(input, 'yyyy-MM-dd');
  }

  return '';
}
