import { useMemo } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid-premium';
import KampusDataGrid from 'components/shared/KampusDataGrid';
import { clientsAsOptionsSelector } from 'features/client/selectors';
import { ClientAsOption } from 'features/client/types';
import { demoModeBroadcast } from 'features/demo-mode/helpers';
import { useAppSelector } from 'store/hooks';

const createClientColumns: (
  onSelect: (clientOption: ClientAsOption) => void,
  onSearchClear: () => void
) => GridColDef<ClientAsOption>[] = (onSelect, onSearchClear) => [
  {
    field: 'label',
    headerName: 'Client',
    flex: 1,
    renderCell: ({ row }) => (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          demoModeBroadcast.postMessage(`/clients/${row.id}/home`);
        }}
      >
        {row.label}
        <OpenInNewIcon sx={{ ml: 1 }} />
      </Button>
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: ({ row }) => (
      <ClientControllerActionsCell
        clientOption={row}
        onSelect={onSelect}
        onSearchClear={onSearchClear}
      />
    ),
  },
];

type ClientControllerDataGridProps = {
  search: string;
  onSearchClear: () => void;
  onSelect: (clientOption: ClientAsOption) => void;
};

export function ClientControllerDataGrid({
  search,
  onSearchClear,
  onSelect,
}: ClientControllerDataGridProps) {
  const clientsAsOptions = useAppSelector(clientsAsOptionsSelector);

  const filteredClients = useMemo(() => {
    const searchLower = search.toLowerCase();

    return clientsAsOptions.filter((client) =>
      client.label.toLowerCase().includes(searchLower)
    );
  }, [clientsAsOptions, search]);

  const columns = useMemo(
    () => createClientColumns(onSelect, onSearchClear),
    [onSelect, onSearchClear]
  );

  return (
    <KampusDataGrid
      rows={filteredClients}
      columns={columns}
      noRowsMessage="No Clients"
    />
  );
}

type ClientControllerActionsCellProps = {
  clientOption: ClientAsOption;
  onSelect: (clientOption: ClientAsOption) => void;
  onSearchClear: () => void;
};

function ClientControllerActionsCell({
  clientOption,
  onSelect,
  onSearchClear,
}: ClientControllerActionsCellProps) {
  return (
    <Box
      sx={{
        marginTop: '2px',
        height: '60px !important',
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          onSearchClear();
          onSelect(clientOption);
        }}
        sx={{
          textWrap: 'wrap',
        }}
      >
        View Advertisers and Campaigns
      </Button>
    </Box>
  );
}
