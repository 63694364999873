import { CategoryNameEnum } from '@kortxio/hub-api';

export const userVisibleMagicBellCategories: readonly string[] = [
  CategoryNameEnum.Billing,
  CategoryNameEnum.Campaign,
  CategoryNameEnum.Dashboard,
  CategoryNameEnum.Document,
  CategoryNameEnum.General,
  CategoryNameEnum.Support,
] as const;
