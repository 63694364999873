// https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid-premium/src/components/GridPremiumColumnMenu.tsx
import React from 'react';
import {
  GridGenericColumnMenu,
  GridColumnMenuProps,
  GridColumnMenuSortItem,
  GridColumnMenuFilterItem,
  GridColumnMenuColumnsItem,
} from '@mui/x-data-grid-premium';

const gridColumnMenuSlots = {
  columnMenuSortItem: GridColumnMenuSortItem,
  columnMenuFilterItem: GridColumnMenuFilterItem,
  columnMenuColumnsItem: GridColumnMenuColumnsItem,
};

const gridColumnMenuSlotProps = {
  columnMenuSortItem: { displayOrder: 10 },
  columnMenuFilterItem: { displayOrder: 20 },
  columnMenuColumnsItem: { displayOrder: 30 },
};

function GridColumnMenu(
  props: GridColumnMenuProps,
  ref: React.Ref<HTMLUListElement>
) {
  return (
    <GridGenericColumnMenu
      ref={ref}
      {...props}
      defaultSlots={gridColumnMenuSlots}
      defaultSlotProps={gridColumnMenuSlotProps}
    />
  );
}

const ForwardRefGridColumnMenu = React.forwardRef<
  HTMLUListElement,
  GridColumnMenuProps
>(GridColumnMenu);

export default ForwardRefGridColumnMenu;
